import Swiper from 'swiper';
import { Pagination, EffectFade, Autoplay, Controller } from 'swiper/modules';

const mastheadCardSlider = new Swiper('.masthead__card__slider', {
    modules: [EffectFade, Pagination, Autoplay, Controller],
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 700,
    effect: "fade",
    allowTouchMove: false,

    pagination: {
        el: '.masthead__card__pagination',
        clickable: true,
    },

    fadeEffect: {
        crossFade: true,
    },   

    autoplay: {
        delay: 4000,
    }, 
});

const mastheadImageSlider = new Swiper('.masthead__image__slider', { 
    modules: [Controller],
    slidesPerView: 1,
    spaceBetween: 0,
    allowTouchMove: false,
    speed: 700,

    autoplay: {
        delay: 4000,
    }, 
});

mastheadCardSlider.controller.control = mastheadImageSlider;
mastheadImageSlider.controller.control = mastheadCardSlider;

/* Header Search */
const searchBtn = document.querySelector('.header__searchbtn');
const searchClose = document.querySelector('.header__search .search__close');
console.log(searchClose)

searchBtn.addEventListener('click', function() {
    document.querySelector('.header__search').classList.toggle('active');
    document.querySelector('.searchform > .search__field').focus();
});

searchClose.addEventListener('click', function() {
  document.querySelector('.header__search').classList.toggle('active');
});


const searchForm = document.querySelector('.searchform');

searchForm.addEventListener('submit', function(e) {
    e.preventDefault();
    const searchInput = document.querySelector('.searchform > input');
        if(searchInput.value !== '') {
            searchForm.submit();
        }
    }
);

/* Mobile Menu */
const mobileMenuBtn = document.querySelector('.header__menu');

mobileMenuBtn.addEventListener('click', function() {
    document.querySelector('.header__nav').classList.toggle('active');
    document.querySelector('.header__menu').classList.toggle('active');
    document.querySelector('body').classList.toggle('noscroll');
  }
);

const menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children > a');
const navigation = document.querySelector('.navigation'); 

menuItemsWithChildren.forEach((menuItem) => {
  menuItem.addEventListener('click', function (event) {

    if (window.innerWidth < 1024) {
      event.preventDefault(); 

      const subMenu = this.nextElementSibling;

      subMenu.classList.toggle('active');

      const isActive = Array.from(document.querySelectorAll('.sub-menu')).some(el => el.classList.contains('active'));

      if (isActive) {
        navigation.classList.add('hide');
      } else {
        navigation.classList.remove('hide');
      }

      let backButton = subMenu.querySelector('.back-button');
      if (!backButton) {
        backButton = document.createElement('button');
        backButton.textContent = 'BACK';
        backButton.className = 'back-button';

        backButton.addEventListener('click', function () {
          subMenu.classList.remove('active');
          navigation.classList.remove('hide');
        });

        subMenu.appendChild(backButton);
      }
    }
  });
});

  